/**
 * Created by Nikita Shirobokov on 5/5/2020.
 */
import Toggle from './Toggle.js';
import computeDistance from '../handlers/computeDistance.js';

export default class ViolationList {
    constructor({container, data, userMarker}) {
        this.container = container;
        this._userMarker = userMarker;
        this._violations = data;
        this._sortableKeys = [
            { name: 'date', direction: true, isSelected: false },
            { name: 'address', direction: true, isSelected: false },
            { name: 'distance', direction: true, isSelected: false },
        ];
        this._disabledSortKeys = [];
        this._updateDisabledSortKeys();

        // Render list
        const { element, sortEl } = this._render();
        this._el = element;
        this._sortToggle = new Toggle({
            element: sortEl,
            header: 'Sort by:',
            items: this._sortableKeys.map(k => k.name),
            selectedItem: this._sortableKeys.filter(k => k.isSelected).map(k => k.name)[0],
            disabledItems: this._disabledSortKeys
        });

        // Set listeners
        this._el.addEventListener('click', e => {
            const targetElem = e.target.closest('[data-action]');
            if (!targetElem) return;
            const listItem = targetElem.closest('li[data-id]');
            if (!listItem) return;
            const { id } = listItem.dataset;

            switch (targetElem.dataset.action) {
                case 'showMore': {
                    const violation = this._violations.find(v => v.id == id);
                    if (!violation) return console.error('Need violation not found');

                    this._el.dispatchEvent(new CustomEvent('openViolationWindow', {
                        bubbles: true,
                        detail: { violation }
                    }));
                } break

                case 'showOnMap': {
                    // Center map
                    this._el.dispatchEvent(new CustomEvent('showMarker', {
                        bubbles: true,
                        detail: {
                            id: `violation${id}`
                        }
                    }));
                }
            }
        });
        this._sortToggle.el.addEventListener('toggleItem', this.sort);
    }
    get sortedKey(){ return this._sortableKeys.filter(k => k.isSelected)[0] }
    get userMarker(){ return this._userMarker }
    set userMarker(value){ 
        this._userMarker = value;
        this._updateDisabledSortKeys();
    }

    sort = e => {
        if (e.stopPropagation) e.stopPropagation();
        const { selectedItem } = e.detail;
        this._sortableKeys.forEach(k => {
            if (k.isSelected && k.name === selectedItem) k.direction = !k.direction;
            k.isSelected = k.name === selectedItem
        });
        if (this._userMarker && selectedItem === 'distance') {
            const userPosition = this._userMarker.getPosition();
            this._violations
                .filter(v => v.position)
                .forEach(v => v.distance = computeDistance(userPosition, v.position));
        }

        // Render changes
        this._sortToggle.render({ selectedItem });
        this._render();
    }

    _sortItems = (v1, v2) => {
        if (!this.sortedKey) return 1;
        const { name, direction } = this.sortedKey;
        switch (name) {
            case 'date': {
                return direction ? v1.date - v2.date : v2.date - v1.date;
            }
            case 'address': {
                const res = direction 
                    ? v1.address.toLowerCase() > v2.address.toLowerCase()
                    : v1.address.toLowerCase() < v2.address.toLowerCase();
                return res ? 1 : -1;
            }
            case 'distance': {
                return direction ? v1.distance - v2.distance : v2.distance - v1.distance;
            }
        }
    }

    _updateDisabledSortKeys(){
        if (!this._userMarker) {
            this._disabledSortKeys.distance = 'Please, check geolocation tracking';
        } else {
            delete this._disabledSortKeys.distance
        }
        if (this._sortToggle) this._sortToggle.render({
            disabledItems: this._disabledSortKeys
        });
    }

    render = (updState = {}) =>{
        Object.entries(updState).forEach(([key, value]) => {
            this[key] = value;
        });
        this._render();
    }

    _render() {
        if (!this._el){
            // Violation list
            const ul = document.createElement('UL');
            ul.className = 'violations violation-map__violations';
            ul.innerHTML = this._renderItems(this._violations);
            // Sort toggle
            const sortEl = document.createElement('UL');
            sortEl.className = 'violations__sort';

            // Render
            this.container.innerHTML = '';
            this.container.prepend(ul);
            ul.after(sortEl);

            return ({ element: ul, sortEl });
        }
        if (!this.container.contains(this._el)) {
            this.container.innerHTML = '';
            this.container.prepend(this._el);
            this._el.after(this._sortToggle.el);
        }
        this._el.innerHTML = this._renderItems(this._violations);
        this._sortToggle.render({ disabledItems: this._disabledSortKeys });

        return this._el;
    }
    _renderItems = violations => {
        return violations.sort(this._sortItems).map(this._renderItem).join('');
    }
    _renderItem = violation => {
        return `<li class="violations__item" data-action="showMore" data-id=${violation.id} ${violation.isViewed ? 'viewed' : ''}>
			<h4>${violation.carName}</h4>
			<p>${violation.car.license_plate.toUpperCase()}<br>${violation.address}<br>${violation.dateString}</p>
			<div class="buttons violations__buttons">
				<button class="gm-button gm-circle material-icons" data-action="showOnMap">place</button>
			</div>
		</li>`;
    }
}