/**
 * Created by Nikita Shirobokov on 5/6/2020.
 */
export default class Toggle {
    constructor({ element, items = [], selectedItem, header, disabledItems = {} }){
        this._items = items;
        this._header = header;
        this._selectedItem = selectedItem || items[0] || '';
        this.disabledItems = disabledItems;
        // Render toggle
        this._el = this._render(element);
        // Set listener
        this._el.addEventListener('click', this.toggle);
    }
    get el(){ return this._el }
    get selectedItem(){ return this._selectedItem }
    set selectedItem(value){ return this._selectedItem = value }

    toggle = e => {
        const targetItem = e.target.closest('.gm-toggle__item[data-id]:not([disabled])');
        if (!targetItem) return;

        this._el.dispatchEvent(new CustomEvent('toggleItem', {
            bubbles: true,
            detail: { selectedItem: targetItem.dataset.id }
        }));
    }

    render = updState => {
        Object.entries(updState).forEach(([key, value]) => {
            this[key] = value;
        });
        this._render();
    }
    _render(element){
        if (!this._el){
            // Create
            element.classList.add('gm-toggle');
            const head = this._header ? `<li class="gm-toggle__head">${this._header}</li>` : '';
            // Render
            element.innerHTML = head + this._items
                .map(item => `<li 
                        class="gm-toggle__item" 
                        data-id="${item}" ${this._selectedItem === item ? 'selected' : ''} 
                        ${this.disabledItems[item] ? `disabled title="${this.disabledItems[item]}"`: ''}
                    >
                        ${item[0].toUpperCase() + item.slice(1)}
                    </li>
                `)
                .join('');
            return element;
        }
        if (this._selectedItem) {
            [...this._el.children]
                .filter(itemEl => 'id' in itemEl.dataset)
                .forEach(itemEl => {
                    if (this._selectedItem === itemEl.dataset.id) {
                        itemEl.setAttribute('selected', '');
                    } else {
                        itemEl.removeAttribute('selected');
                    }
                    if (this.disabledItems[itemEl.dataset.id]) {
                        itemEl.setAttribute('disabled', '');
                        itemEl.setAttribute('title', this.disabledItems[itemEl.dataset.id]);
                    } else {
                        itemEl.removeAttribute('disabled');
                        itemEl.removeAttribute('title');
                    }
                })
        }
        return this._el;
    }
}