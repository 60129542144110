/**
 * Created by Nikita Shirobokov on 5/5/2020.
 */
export default class UserLocationControl {
    constructor(map) {
        this._map = map;
        this._isActive = false;
        this._isHold = false;
        this.title = '';
        this.icon = '';
        // Add to map
        this._el = this._render();
        // Set listeners
        this._el.addEventListener('click', this.dispatch);
    }
    get isActive(){ return this._isActive }
    set isActive(value){ 
        this._isActive = value;
        if (!value) {
            this._isHold = false;
        }
    }
    get isHold(){ return this._isHold }
    set isHold(value){
        this._isHold = value;
        if (value) {
            this._isActive = true;
        }
    }

    dispatch = e => {
        e.stopPropagation();
        this._el.dispatchEvent(new CustomEvent('clickCustomControl', {
            bubbles: true,
            detail: this._el.dataset
        }));
        // Turn loading mode
        this.render({ isLoading: true, title: 'Search your location' });
    }

    render = updState => {
        Object.entries(updState).forEach(([key, value]) => {
            this[key] = value;
        });
        this._render();
    }
    _render() {
        if (!this._el) {
            // Create button
            const button = document.createElement('BUTTON');
            button.className = 'gm-button gm-circle material-icons';
            button.setAttribute('data-control-name', 'userLocation');
            button.index = 0;
            button.textContent = 'gps_not_fixed';
            // Render
            this._map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(button);
            return button;
        }
        this._el.title = this.title;
        this._renderMode();

        return this._el;
    }
    _renderMode(){
        if (this._isHold || this.isLoading) {
            this._el.setAttribute('selected', '');
        } else {
            this._el.removeAttribute('selected');
        }
        if (this.isLoading) {
            return this._el.textContent = 'person_search';
        }
        this._el.textContent = `gps${this._isActive ? '' : '_not'}_fixed`;
    }
}