/**
 * Created by Nikita Shirobokov on 5/7/2020.
 */
import makeTimeString from './makeTimeString.js';

export default function makeReadableDateString(date) {
    if (!(date instanceof Date)) return '';
    if (!date.toLocaleDateString){
        return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()} ${makeTimeString(date)}`;
    }
    return date.toLocaleDateString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        timeZone: 'UTC',
        hour12: true 
    }).replace(',', '');
}