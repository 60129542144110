/**
 * Created by Nikita Shirobokov on 5/5/2020.
 */
import makeReadableDateString from '../handlers/makeReadableDateString.js';
import convertTimestampToDate from '../handlers/convertTimestampToDate.js';

export default class Violation {
    constructor(data, map) {
        this._map = map;
        this._pos = new google.maps.LatLng(parseFloat(data.lat), parseFloat(data.lng));
        this._date = convertTimestampToDate(data.date);
        this.id = data.id;
        this.address = data.address || '—';
        this.city = data.city || '';
        this.country = data.country || '';
        this.imageUrl = data.imageUrl || '';
        this.car = Object.entries(data)
            .filter(([key]) => key.indexOf('car_') === 0)
            .map(([key, value]) => {
                switch (key) {
                    case 'car_brand': return ['car_make', value];
                    case 'car_number': return ['car_license_plate', value];
                    default: return [key, value];
                }
            })
            .reduce((obj, [key, value]) => {
                obj[key.slice(4)] = value || '—';
                return obj;
            }, {});
    }
    get carName() { return `${this.car.color} ${this.car.make} ${this.car.model}` }
    get date(){ return this._date }
    get dateString() { return makeReadableDateString(this._date) }
    get position() { return this._pos }
    get longAddress() { return this.address + (this.city ? `, ${this.city}, ${this.country}` : '') }
}