/**
 * Created by Nikita Shirobokov 6/2/2020
 */
import getImageOrientation from './getImageOrientation.js';

const ASPECT_RATIO = 16 / 9;
const MAX_WIDTH = 1024;
const MAX_HEIGHT = MAX_WIDTH / ASPECT_RATIO;

export default async function normalizeImageOrientation(fileURL) {
    if (!fileURL) return '';
    if (navigator.userAgent.match(/Chrome\/[.0-9]*/)) return fileURL;
    const imageFile = await fetch(fileURL).then(res => res.blob()).catch(() => null);
    if (!imageFile) return fileURL;
    
    return await new Promise(res => {
        const image = new Image();
        image.setAttribute('crossorigin', '');
        // Handle loading
        image.onload = async e => {
            // Get image data
            let { width, height } = image;
            // calculate the width and height, constraining the proportions
            if (width > height) {
                if (width > MAX_WIDTH) {
                    height = Math.round(height *= MAX_WIDTH / width);
                    width = MAX_WIDTH;
                }
            } else {
                if (height > MAX_HEIGHT) {
                    width = Math.round(width *= MAX_HEIGHT / height);
                    height = MAX_HEIGHT;
                }
            }
            const orientation = await getImageOrientation(imageFile);

            // Create canvas
            const canvas = document.createElement('canvas');            
            // Set context
            const ctx = canvas.getContext('2d');

            // Rotate by orientation
            console.log('Rotate by:', orientation, image);
            switch (orientation) {
                case 6:
                case 8: {
                    canvas.width = height;
                    canvas.height = width;

                    ctx.translate(canvas.width / 2, canvas.height / 2);
                    ctx.rotate((orientation === 6 ? 90 : -90) * Math.PI / 180);
                    ctx.drawImage(image, -width / 2, -height / 2, width, height);
                    res(canvas.toDataURL('image/jpeg'));
                }
                break;
                default: res('');
            }
        }
        image.onerror = e => res('');
        // Set image
        image.src = URL.createObjectURL(imageFile);
    });
}

/**
 * TODO: Make canvas drawing for all orientations
 * https://github.com/DmitryMakhnev/iLoveFrontend2019/blob/master/src/js/libs/exif/tags/TIFFTagOrientationValue.js
 * https://github.com/DmitryMakhnev/iLoveFrontend2019/blob/master/src/js/ui/components/examples/withOrientation/withOrientationExampleViewModel.js
 */