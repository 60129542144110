/**
 * Created by Nikita Shirobokov on 5/5/2020.
 */
import Violation from '../components/Violation.js';

export default class DataService {
    constructor(map, geocoder){
        this._map = map;
        this._geocoder = geocoder;
    }
    
    async getViolations(){
        const violationData = await this._get(new URL('/violations-map/data', location.origin)) || fakeLocalData.filter(i => i.imageUrl);
        if (!violationData) return [];
        return violationData.map(data => new Violation(data));
    }

    async findUserPosition() {
        if (navigator.geolocation) {
            return await new Promise(res => {
                navigator.geolocation.getCurrentPosition(
                    position => {
                        const { latitude, longitude } = position.coords;
                        res(new google.maps.LatLng(latitude, longitude));
                    },
                    () => {
                        console.warn('Geolocation is not working: Please, check permissions.');
                        res('Geolocation is not working: Please, check permissions.');
                    }
                );
            });
        } else {
            console.warn(`Browser doesn't support Geolocation`);
            return `Browser doesn't support Geolocation`;
        }
    }

    async getLocation(position) {
        if (typeof position !== 'object') return 'Invalid latitude and longitude';
        // Geocode
        return await new Promise(res => {
            this._geocoder.geocode({ location: position }, (results, status) => {
                if (status === 'OK') {
                    if (results[0]) {
                        const { formatted_address = '', address_components = [] } = results[0];
                        return res(formatted_address);
                    }
                }
                // Position not found
                console.warn(`${position} is ${status}`);
                res(position.toString());
            });
        });
    }

    async _get(url, getterMethod = 'json'){
        try {
            const response = await fetch(url);
            if (response && response.ok) {
                return await response[getterMethod]();
            } else {
                console.error(`${response.status} for query: ${url}`);
                return null;
            }
        } catch (e) {
            console.error(`${e} for query: ${url}`);
            return null;
        }
    }
}

// Fake data
const fakeLocalData = [
    {
        id: 0,
        address: 'Sukharnaya, 101/1', 
        car_brand: 'Mitsubishi',
        car_color: 'Black',
        car_model: 'Lancer',
        car_number: 'B988EH159',
        city: 'Novosibirsk', 
        country: 'Russia',
        date: 1452851184,
        imageUrl: 'img/Grsg7ezX3btl-xcFY4EAm3rJ0c-0T6JI.jpg',
        lat: 55.047894,
        lng: 82.862172,
    },
    {
        id: 1,
        address: 'Sukharnaya, 101/1',
        car_brand: 'Ford',
        car_color: 'Blue',
        car_model: 'Focus',
        car_number: 'M636PC54',
        city: 'Novosibirsk',
        country: 'Russia',
        date: 1515069094,
        imageUrl: 'img/tMtwnBmbEI6U2an8oSX8xJwVLUPL0CYK.jpg',
        lat: 55.048136,
        lng: 82.861711,
    },
    {
        id: 2,
        address: 'Sukharnaya, 101/1',
        car_brand: 'Audi',
        car_color: 'Gray',
        car_model: 'A3',
        car_number: 'N118YU01',
        city: 'Novosibirsk',
        country: 'Russia',
        date: 918410400,
        imageUrl: 'img/jjjd3334nns.jpeg',
        lat: 55.058136,
        lng: 82.861711,
    }
]