/**
 * Created by Nikita Shirobokov on 5/5/2020.
 */
import makeReadableDistanceString from '../handlers/makeReadableDistanceString.js';

export default class ViolationWindow {
    constructor({ container, data }) {
        this.container = container;
        this._violation = data;
        // Create window
        this._el = this._render();
        // Set listeners
        this._el.addEventListener('click', e => {
            const targetElem = e.target.closest('[data-action]');
            if (!targetElem) return;

            switch (targetElem.dataset.action) {
                case 'close': {
                    this._el.dispatchEvent(new CustomEvent('closeViolationWindow', {
                        bubbles: true,
                        detail: {
                            violation: this._violation
                        }
                    }));
                } break

                case 'showOnMap': {
                    // Center map
                    this._el.dispatchEvent(new CustomEvent('showMarker', {
                        bubbles: true,
                        detail: {
                            id: `violation${this._violation.id}`
                        }
                    }));
                }
            }
        });
    }
    get data(){ this._violation }
    set data(value){ this._violation = value }

    render = (updState = {}) => {
        Object.entries(updState).forEach(([key, value]) => {
            this[key] = value;
        });
        this._render();
    }
    _render(){
        if (!this._el) {
            // Violation list
            const article = document.createElement('ARTICLE');
            article.className = 'violation violation-map__violation';
            article.innerHTML = this._renderContent(this._violation);

            // Render
            this.container.innerHTML = '';
            this.container.prepend(article);

            return article;
        }
        if (!this.container.contains(this._el)) {
            this.container.innerHTML = '';
            this.container.prepend(this._el);
        }
        this._el.innerHTML = this._renderContent(this._violation);

        return this._el;
    }
    _renderContent = v =>{
        const carSpec = ['make', 'model', 'color', 'license_plate']
            .map(k => `${(k[0].toUpperCase() + k.slice(1)).replace('_', ' ')}: <b>${v.car[k]}</b>`)
            .join("<br>");
        
        return `<section class="violation__image" style="background-image: url(${v.normalImageUrl || v.imageUrl});"></section>
            <ul class="violation__info">
                <li class="violation__item" data-action="showOnMap">
                    <section class="violation__icon">
                        <i class="material-icons">place</i>
                        <span>${makeReadableDistanceString(v.distance)}</span>
                    </section>
                    <p>${v.longAddress}</p>
                </li>
                <li class="violation__item">
                    <section class="violation__icon">
                        <i class="material-icons">drive_eta</i>
                    </section>
                    <p>${carSpec}</p>
                </li>
                <li class="violation__item">
                    <section class="violation__icon">
                        <i class="material-icons">today</i>
                    </section>
                    <p>${v.dateString}</p>
                </li>
            </ul>
            <button class="gm-button gm-button-target gm-circle violation__gm-button material-icons" data-action="close">close</button>`
    }
}