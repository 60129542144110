/**
 * Taken from https://stackoverflow.com/a/32490603
 * Edited by Nikita Shirobokov 6/2/2020
 */
export default function parseExif(e) {
    const view = new DataView(e.target.result);
    
    if (view.getUint16(0, false) != 0xFFD8) return -2; // No image

    let offset = 2;
    while (offset < view.byteLength) {
        if (view.getUint16(offset + 2, false) <= 8) return -1; // Not defined
        const marker = view.getUint16(offset, false);

        offset += 2;
        if (marker == 0xFFE1) {
            if (view.getUint32(offset += 2, false) != 0x45786966) return -1; // Not defined

            const little = view.getUint16(offset += 6, false) == 0x4949;
            offset += view.getUint32(offset + 4, little);
            offset += 2;
            for (var i = 0; i < view.getUint16(offset, little); i++) {
                if (view.getUint16(offset + (i * 12), little) == 0x0112) {
                    return view.getUint16(offset + (i * 12) + 8, little); // Orientation [1-8]
                }
            }
        }
        else if ((marker & 0xFF00) != 0xFF00) break;
        else {
            offset += view.getUint16(offset, false);
        }
    }
    // Not defined
    return -1;
}