/**
 * Created by Nikita Shirobokov on 5/5/2020.
 */
import SwipeListener from 'swipe-listener';
import DataService from '../services/DataService.js';
import ViolationList from './ViolationList.js';
import UserLocationControl from '../custom-controls/UserLocationControl.js';
import Toggle from './Toggle.js';
import ViolationWindow from './ViolationWindow.js';
import computeDistance from '../handlers/computeDistance.js';
import normalizeImageOrientation from '../handlers/normalizeImageOrientation.js';

const USER_LOCATION_HOLDING_MS_INTERVAL = 1750; 
const DEFAULT_ZOOM = 21;
export default class ViolationMap {
    constructor(container) {
        // Properties
        this._markers = {};
        this._modes = [
            { name: 'map', isSelected: true },
            { name: 'list', isSelected: false },
            { name: 'window', isSelected: false },
            { name: 'windowFullscreen', isSelected: false }
        ];
        this._userLocationControl = null;

        // Create general layout
        const { element, mapContainer, aside, toggleEl } = this._render(container);
        this._el = element;
        this._aside = aside;
        this._mapContainer = mapContainer;
        this._modeToggle = new Toggle({
            element: toggleEl,
            items: this._modes.filter(m => !m.name.includes('window')).map(m => m.name),
            selectedItem: this.mode
        });

        // Set listener
        this._el.addEventListener('showMarker', this.showMarker);
        this._el.addEventListener('openViolationWindow', this.openViolation);
        this._el.addEventListener('closeViolationWindow', e => this._closeViolation());
        this._modeToggle.el.addEventListener('toggleItem', e => {
            if (this.mode.includes('window')) this._closeViolation();
            this._setMode(e.detail.selectedItem);
        });
        this.swipeListener = SwipeListener(this._aside);
    }
    get map() { return this._map }
    get mode() { return this._modes.find(m => m.isSelected).name }
    
    start = async () => {
        try {
            await this._init();
            await this._loadViolations();
            // Default presets
            this.trackUserLocation(true);

            return this;
        } catch (e) {
            console.error(e)
            return e;
        }
    }

    handleDragStart = e => {
        this._stopHoldingUserInCenter();
    }
    
    handleClickCustomControl = ({ detail }) => {
        const { controlName } = detail;
        // Handle control
        switch (controlName) {
            case 'userLocation': this.trackUserLocation(true);
            break;
        
            default: console.error(`${controlName || 'Unknow'} control not found`);
        }
    }
    handleSwipe = e => {
        if (e.detail.directions.bottom) { // SWIPE DOWN
            if (this.mode === 'windowFullscreen') {
                return this._setMode('window');
            }
            this._modeBeforeWindow = 'map'; // Show map
            this._closeViolation();
        }
        if (e.detail.directions.top) {
            this._setMode('windowFullscreen');
        }
    };
    /* Listener handlers */
    showMarker = e => {
        const { id, animationName, inCenter = true } = e.detail;
        const marker = this._markers[id];
        if (!marker) return console.error(`${id} marker not found`);
        // Show
        this._stopHoldingUserInCenter();
        if (inCenter) this._centerMarker(marker);
        this.animateMarker(marker, animationName);
        
        if (this.mode !== 'window') this._setMode('map');
    }
    openViolation = e => {
        const { violation } = e.detail;
        this._openViolation(violation);

        this.showMarker({ 
            detail: { 
                id: `violation${violation.id}`,
                animationName: 'DROP'
            } 
        });
    }
    _closeViolation = (violation = this._openedViolation) => {
        if (violation) {
            // Reset marker
            this._setMarker(this._markers[`violation${violation.id}`], { icon: { url: 'img/markers/car-gray.svg' } });
        }
        this._violationList.render();
        this._setMode(this._modeBeforeWindow);
        // Reset memory
        this._aside.removeEventListener('swipe', this.handleSwipe);
        this._openedViolation = null;
    }

    /* Open violation window */
    _openViolation(violation) {
        if (this.mode !== 'window') {
            // Set window mode
            this._modeBeforeWindow = this.mode;
            this._setMode('window');
        }
        if (this._markers['userMarker']) {
            // Compute new distance from user position
            violation.distance = computeDistance(this._markers['userMarker'].getPosition(), violation.position);
        }
        // Normal orientation image
        if (!violation.normalImageUrl) {
            normalizeImageOrientation(violation.imageUrl).then(normalImageUrl => {
                violation.normalImageUrl = normalImageUrl;
                this._violationWindow.render({ data: violation });
            });
        }
        // Open window
        if (!this._violationWindow) {
            this._violationWindow = new ViolationWindow({
                container: this._aside,
                data: violation
            });
        } else {
            this._violationWindow.render({ data: violation });
        }
        // Highlight marker
        if (this._openedViolation) {
            this._setMarker(this._markers[`violation${this._openedViolation.id}`], { icon: { url: 'img/markers/car-gray.svg' } });
        }
        this._setMarker(this._markers[`violation${violation.id}`], { icon: { url: 'img/markers/car-red.svg' } });
        // Set swiper
        const listener = this.swipeListener;
        this._aside.addEventListener('swipe', this.handleSwipe);
        // Show marker
        this.showMarker({
            detail: {
                id: `violation${violation.id}`,
                animationName: 'DROP',
                inCenter: false
            }
        });
        // Save
        this._openedViolation = violation;
    }

    /* Handle user location */
    _stopHoldingUserInCenter() {
        this._userLocationControl.render({ isHold: false });
    }
    trackUserLocation = async withHold => {
        if (this._userTracking) clearTimeout(this._userTracking);
        const userPosition = await this._service.findUserPosition();
        
        let userLocationControlProps = { isLoading: false };
        switch (true) {
            case typeof userPosition !== 'object': {
                userLocationControlProps = { ...userLocationControlProps, isActive: false, title: userPosition };
                if (this._markers['userMarker']) {
                    this._markers['userMarker'].setMap(null);
                    this._markers['userMarker'] = null;
                    this._violationList.render({ userMarker: this._markers['userMarker'] });
                }
            }
            break;

            case !this._markers['userMarker']: {
                // create marker with user position
                this._createUserMarker(userPosition);
                this._violationList.render({ userMarker: this._markers['userMarker'] });
                
                userLocationControlProps = { ...userLocationControlProps, isActive: true, title: await this._service.getLocation(userPosition) };
            }
            break;

            case this._markers['userMarker'].getPosition().toString() !== userPosition.toString(): {
                // upd marker position
                this._markers['userMarker'].setPosition(userPosition);
                userLocationControlProps = { ...userLocationControlProps, isActive: true, title: await this._service.getLocation(userPosition) };
            }
        }
        if (this._markers['userMarker'] && withHold) {
            userLocationControlProps = { ...userLocationControlProps, isHold: true };
        }
        // Render changes
        this._userLocationControl.render(userLocationControlProps);
        // Hold marker in center
        if (this._userLocationControl.isHold) this._centerMarker(this._markers['userMarker'], this._map.getZoom());
        // Start recursion
        this._userTracking = setTimeout(this.trackUserLocation, this._userLocationControl.isHold ? USER_LOCATION_HOLDING_MS_INTERVAL : 10000);
    }

    /* Create markers */
    _createUserMarker(position) {
        this._createMarker('userMarker', {
            position,
            title: `I'm here`,
            icon: {
                url: 'img/markers/user-dot.svg',
                size: new google.maps.Size(24, 24),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(0, 0)
            }
        });
    }
    _createViolationMarker(violation) {
        const { id, position, carName } = violation;
        this._createMarker(`violation${id}`, {
            position,
            title: carName,
            icon: {
                url: 'img/markers/car-gray.svg',
                size: new google.maps.Size(32, 32),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(0, 0)
            }
        }, e => {
            this._openViolation(violation);
        });
    }
    _createMarker(id, options = {}, markerClickHandler){
        if (id === undefined) return console.error(`Can't create marker without ID`);
        if (!options.position) return console.error(`Marker must have a position`);
        // Create
        this._markers[id] = new google.maps.Marker({
            map: this._map,
            ...options
        });
        // Set listener
        this._markers[id].addListener('click', e => {
            if (markerClickHandler) markerClickHandler(e);
        });

        return this._markers[id];
    }

    /* Logic methods */
    _setMode(selectedItem) {
        this._modes.forEach(m => m.isSelected = m.name === selectedItem);
        // Render changes
        this._modeToggle.render({ selectedItem });
        this._render();
    }
    _setMarker = (marker, options = {}) => {
        const { icon, position } = options;
        if (icon) marker.setIcon({ ...marker.getIcon(), ...icon });
        if (position) marker.setPosition(position);
    }

    _centerMarker(marker, zoom = DEFAULT_ZOOM) {
        this._map.setCenter(marker.getPosition());
        this._map.setZoom(zoom);
    }

    animateMarker(marker, animationName) {
        if (this._animatedMarker) {
            this._animatedMarker.setAnimation(null);
            this._animatedMarker = null;
        }
        switch (animationName) {
            case 'DROP': this._animateMarkerAsDrop(marker);
            break;
            default: this._animateMarkerAsBounce(marker);
            break;
        }
        this._animatedMarker = marker;
    }
    _animateMarkerAsDrop(marker) {
        // Animate marker
        setTimeout(() => {
            if (!marker.getAnimation()) {
                marker.setAnimation(google.maps.Animation.DROP); // {BOUNCE: 1, DROP: 2, So: 3, Qo: 4}
            }
            setTimeout(() => {
                this._clearAnimation(marker);   
            }, 500);
        });
    }
    _animateMarkerAsBounce(marker) {
        if (this._bouncedMarker) {
            this._clearAnimation(this._bouncedMarker);
            this._bouncedMarker = null;
        }
        if (!marker.getAnimation()) {
            marker.setAnimation(google.maps.Animation.BOUNCE); // {BOUNCE: 1, DROP: 2, So: 3, Qo: 4}
            this._bouncedMarker = marker;
        }
    }
    _clearAnimation(marker){
        marker.setAnimation(null);
    }
    
    /* Structure methods */
    _loadViolations = async () => {
        if (!this._aside) return console.error(`ASIDE node doesn't rendered`);
        this._violations = await this._service.getViolations();
        await Promise.all([
            await new Promise(res => {
                // Create list
                this._violationList = new ViolationList({
                    container: this._aside,
                    data: this._violations,
                    userMarker: this._userMarker
                });
                this._violationList.sort({ detail: { selectedItem: 'date' } });
                res(true)
            }),
            await new Promise(res => {
                // Add markers
                this._violations.forEach(v => this._createViolationMarker(v));
                res(true);
            })
        ]);
        this._cluster = new MarkerClusterer(
            this._map, 
            Object.entries(this._markers).filter(([id]) => id !== 'userMarker').map(([id, m]) => m),
            { 
                maxZoom: DEFAULT_ZOOM - 1,
                imagePath: 'img/markers/cluster',
                imageExtension: 'svg',
                imageSizes: [56, 64, 72, 86, 98]
            });
        
        return this;
    }
    _init = async () => {
        if (this._map || !this._mapContainer) return console.error('Map is initialized or Map container not found');
        // Initialization map
        this._map = new google.maps.Map(this._mapContainer, {
            center: { lat: -34.397, lng: 150.644 },
            disableDefaultUI: true,
            gestureHandling: 'greedy',
            zoom: DEFAULT_ZOOM,
            zoomControl: true,
            zoomControlOptions: {
                position: google.maps.ControlPosition.RIGHT_BOTTOM,
                index: 2
            },
        });
        this._geocoder = new google.maps.Geocoder();
        this._service = new DataService(this._map, this._geocoder);
        // Custom control
        this._userLocationControl = new UserLocationControl(this._map);
        
        // Set map container listeners
        google.maps.event.addDomListener(this._mapContainer, 'clickCustomControl', this.handleClickCustomControl);
        // Set map listeners
        this._map.addListener('dragstart', this.handleDragStart);
        
        return this;
    }
    _render(container) {
        if (!this._el) {
            // Create mode toggle
            const toggleEl = document.createElement('UL');
            toggleEl.className = 'violation-map__gm-toggle';
            // Create aside block
            const aside = document.createElement('ASIDE');
            // Create map block
            const map = document.createElement('MAP');

            // Render
            container.setAttribute('mode', this.mode);
            container.prepend(toggleEl);
            container.prepend(aside);
            container.prepend(map);

            return { aside, element: container, mapContainer: map, toggleEl };
        }
        this._el.setAttribute('mode', this.mode)

        return this._el;
    }
}