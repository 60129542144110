/**
 * Created by Nikita Shirobokov on 5/3/2020.
 */
import ViolationMap from './components/ViolationMap.js';

function initViolationMap() {
    // Start violation map
    const violationMap = new ViolationMap(document.getElementById('violationMap'));
    violationMap.start().finally(() => console.log('Violation map is running!'));
}

google.maps.event.addDomListener(window, 'load', initViolationMap);