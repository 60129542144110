
/**
 * Created by Nikita Shirobokov 6/2/2020
 */
import parseExif from './parseExif.js';

export default async function getImageOrientation(file) {
    if (!file) return null;
    return await new Promise(res => {
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onload = e => res(parseExif(e));
    });
}